import module from './index'

let moduleRoute = module.info.routeName;
const routes = [
  {
    path: module.info.name,
    name: moduleRoute,
    component: () => import('./View.vue'),
    children: [
      {
        path: 'control',
        name: moduleRoute + '->control',
        component: () => import('./control/View'),
      }
    ]
  }
];

export default routes
